//const gatewaysCacheKey = 'betternetworks:gateways';
//const devicesCacheKey = 'betternetworks:devices';
//let VM;

import moment from "moment";

export default {
  semrushGetReport: (self, serviceId, forced = false) =>
    new Promise((resolve, reject) => {
      self.$api
        .getWithCache(
          self,
          `advertising`,
          `${self.$root.clientId}/${serviceId}/semrush/report`,
          `semrush-report-${self.$root.clientId}-${serviceId}-`,
          forced
        )
        .then((x) => resolve(x))
        .catch(reject);
    })
};
