<template>
  <div v-if="$route.params.taskId !== undefined">
    <KTPortlet v-if="portalReport === null || definedTask === null" title="SEO Tasks" data-app="seo-report">
      <template slot="body">
        <Loader :inContent="true" :noText="true">Loading...</Loader>
      </template>
    </KTPortlet>
    <div v-else>
      <KTPortlet :title="`${ definedTask.name } for ${ portalReport.info.title }`" data-app="seo-report"><template
          v-slot:title>
          <b-button @click="$router.push(`/Web/${ $route.params.serviceId }/SEOTasks`)" pill size="sm"
            variant="outline-primary text-primary">&#60;
          </b-button>
          <h3 class="kt-portlet__head-title" style="margin-left: 10px;">
            {{ `${ definedTask.name } for ${ portalReport.info.title }` }}
          </h3>
        </template>
        <template slot="body">
          <h5>{{ definedTask.description }}</h5>

          <br />
          <br />
          status: {{ definedTask.status }} - priority: {{ definedTask.priority }} - estimate:{{
              definedTask.estimationDays !== null ? `${ definedTask.estimationDays } days ` : ''
          }}
          {{ definedTask.estimationHours !== null ? `${ definedTask.estimationHours } hours` : '' }}
          {{ definedTask.dueDate !== null ? ' - due: ' + formatDateT(definedTask.dueDate) : '' }}
          <template v-if="definedTask.comments.length > 0">
            <h6>Comments:</h6>
            <p v-for="comment of definedTask.comments" v-bind:key="comment.id">
              Author: {{ comment.author }}<br />
              Comment: <b>{{ comment.message }}</b>
            </p>
          </template>
        </template>
      </KTPortlet>
    </div>
  </div>
  <div v-else>
    <KTPortlet title="SEO Tasks" data-app="seo-report">
      <template slot="body">
        <div v-if="portalReport !== null && portalReport.tasks === null">No tasks to display</div>
        <div v-else-if="portalReport !== null && portalReport.tasks.length === 0">No tasks to display</div>
        <b-table-simple responsive v-else>
          <b-thead>
            <b-tr>
              <b-th>Status</b-th>
              <b-th>Priority</b-th>
              <b-th>Name</b-th>
              <b-th>Comments</b-th>
              <b-th>Est. Time</b-th>
              <b-th>Due date</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="portalReport === null">
            <b-tr v-for="i in 10" v-bind:key="i">
              <b-th>
                <Loader :newStyle="1" />
              </b-th>
              <b-td>
                <Loader :newStyle="1" />
              </b-td>
              <b-td>
                <Loader :newStyle="1" />
              </b-td>
              <b-td>
                <Loader :newStyle="1" />
              </b-td>
              <b-td>
                <Loader :newStyle="1" />
              </b-td>
              <b-td>
                <Loader :newStyle="1" />
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tbody v-else>
            <b-tr v-for="itask in portalReport.tasks" v-bind:key="itask.id">
              <b-th>{{ itask.status }}
              </b-th>
              <b-td>{{ itask.priority }}
              </b-td>
              <b-td>{{ itask.name }}
              </b-td>
              <b-td>{{ itask.comments.length }}
              </b-td>
              <b-td>{{ itask.estimationDays !== null ? `${ itask.estimationDays } days ` : '' }}
                {{ itask.estimationHours !== null ? `${ itask.estimationHours } hours` : '' }}
              </b-td>
              <b-td>{{ itask.dueDate !== null ? formatDateT(itask.dueDate) : '' }}
              </b-td>
              <b-td style="width: 110px; text-align:right;">
                <b-button @click="$router.push(`/Web/${ $route.params.serviceId }/SEOTasks/${ itask.id }`)" pill
                  size="sm" variant="primary text-light">VIEW
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
//import ApiService from "@/common/api.service";

export default {
  components: {
    KTPortlet,
    Loader,
    apexcharts: VueApexCharts
  },
  watch: {},
  data() {
    return {
      portalReport: null,
      /*showConversions: false,
      stats: null,
      usage: null,
      start: null,
      end: null,
      chartOptions: {
        colors: ["#0091ea", "#6200ea", "#00c853", "#ffd600"],
        chart: {
          type: "area",
          height: 350
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },

        xaxis: {
          labels: {
            formatter: function(value, timestamp) {
              return moment(new Date(timestamp)).format("DD/MM/YYYY");
            }
          },
          type: "datetime",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            / *formatter: function(value) {
              return (value * (value < 0 ? -1 : 1)).toFixed(2) + " GB";
            },* /
            offsetY: -7,
            offsetX: 0
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 0.5
        },
        tooltip: {
          x: {
            format: "datetime"
          },
          fixed: {
            enabled: false,
            position: "topRight"
          }
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30
            }
          },
          padding: {
            left: 20
          }
        }
      },
      suggestion: null*/
    };
  },
  methods: {
    formatDateT(datee) {
      return moment(datee).format('DD/MM/YYYY');
    },
    /*getReportInstanceById(report) {
      for (let instance of report.instance) {
        if (instance.id === report._instance) return instance;
      }
      return null;
    },*/
    updateService(serviceId) {
      let self = this;
      self.$data.portalReport = null;
      /*self.$data.suggestion = null;
      TLib.getAdBudSuggestions(self, serviceId).then(sugg => {
        if (sugg.description === undefined) return;
        if (sugg.completed === true) return;
        self.$data.suggestion = sugg;
      }).catch(self.$log.error);*/
      TLib.semrushGetReport(self, serviceId)
        .then(z => {
          //for (let reportIndex = 0; reportIndex < z.reports.length; reportIndex++) {
          //if (z.reports[reportIndex].instance.length == 0) continue;
          //z.reports[reportIndex]._instance = z.reports[reportIndex].instance[0].interactiveHtmlLink;
          //z.reports[reportIndex].instance.push(JSON.parse(JSON.stringify(z.reports[reportIndex].instance[0])));
          //z.reports[reportIndex].instance[1].generatedAt = '2022-10-21T11:42:47.000Z';
          //z.reports[reportIndex].instance[1].interactiveHtmlLink = '2022-10-21T11:42:47.000Z';

          //for (let instanceIndex = 0; instanceIndex < z.reports[reportIndex].instance.length; instanceIndex++) {
          //  z.reports[reportIndex].instance[instanceIndex].text = moment(z.reports[reportIndex].instance[instanceIndex].generatedAt).format('DD/MM/YYYY');
          //  z.reports[reportIndex].instance[instanceIndex].value = z.reports[reportIndex].instance[instanceIndex].interactiveHtmlLink;
          //}
          //}
          self.$data.portalReport = z;
        })
        .catch(self.$log.error);
      // return;
      // self.updateUsage(serviceId);
      // self.$data.service = null;
      // TLib.getSIM(this, serviceId)
      //   .then(x => {
      //     self.$nextTick(() => {
      //       self.$data.service = x;
      //     });
      //   })
      //   .catch(x => {
      //     self.$log.error(x);
      //     self.$log.error("Error loading content");
      //   });
    }
    /*updateUsage(serviceId) {
      let self = this;
      self.$data.usage = null;
      TLib.getUsage(this, serviceId)
        .then(x => {
          self.$nextTick(() => {
            self.$data.usage = x;
          });
        })
        .catch(x => {
          self.$log.error(x);
          self.$log.error("Error loading content");
        });
    }*/
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    definedTask() {
      if (this.$route.params.taskId === undefined) return null;
      for (let task of this.portalReport.tasks) {
        if (this.$route.params.taskId == task.id) return task;
      }
      return null;
    },

    /*chartData() {
      let data = [
        {
          name: "Clicks",
          data: []
        },
        {
          name: "Impressions",
          data: []
        }
      ];

      if (this.showConversions) {
        data.push({
          name: "Conversions",
          data: []
        });
      }

      if (this.$tools.isNullOrUndefined(this.stats)) return null;
      if (this.$tools.isNullOrUndefined(this.stats.account_daily)) return null;

      for (let itemI of Object.keys(this.stats.account_daily)) {
        let item = this.stats.account_daily[itemI];
        data[0].data.push({
          x: item.date,
          y: item.clicks
        });
        data[1].data.push({
          x: item.date,
          y: item.impressions
        });
        if (this.showConversions) {
          data[2].data.push({
            x: item.date,
            y: item.conversions
          });
        }
      }

      return data;
    }*/
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
    //this.$eventBus.off(`cache-crm:services`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Web" },
      { title: "SEO Tasks" }
    ]);

    /*if (
      !this.$tools.isNullOrUndefined(this.config.charts) &&
      !this.$tools.isNullOrUndefined(this.config.charts.colours)
    )
      this.$data.chartOptions.colors = this.config.charts.colours;*/

    this.updateService(this.$route.params.serviceId);

    let self = this;

    self.$eventBus.on(`switch-service-selected`, x => self.updateService(x));
    //self.$eventBus.on(`cache-crm:services`, () => self.resync(false));
    //self.resync();
  }
};
</script>
